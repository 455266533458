import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f10f2674"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "review-confirm-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_breadcrumb = _resolveComponent("breadcrumb")!
  const _component_route_tabs = _resolveComponent("route-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_breadcrumb, { dataSource: [] }),
    _createVNode(_component_route_tabs, {
      "route-name": "controllingProgramHome",
      onSaveData: _ctx.handleGetGuideLineTodoCount
    }, {
      tab: _withCtx(({route}) => [
        _createTextVNode(_toDisplayString(route.meta.title) + _toDisplayString(route.name === 'controllingProgramHome Set Guideline' ? `(To-do:${_ctx.guideLineTodoCount})` : ''), 1)
      ]),
      _: 1
    }, 8, ["onSaveData"])
  ]))
}