
    import { defineComponent, ref } from 'vue';
    import RouteTabs from "@/views/Payment/components/RouteTabs.vue";
    import { getGuideLineTodoCount } from '@/API/budget'


    export default defineComponent({
        name: "ProgramHomePage",
        components: {
          RouteTabs
        },
        setup() {
            const guideLineTodoCount = ref(0)

            const handleGetGuideLineTodoCount = async () => {
              guideLineTodoCount.value = await getGuideLineTodoCount()
            }

            handleGetGuideLineTodoCount()

            return {
                guideLineTodoCount,
                handleGetGuideLineTodoCount
            }
        }
    })
